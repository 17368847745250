.btn {
    display: block;
    cursor: pointer;
    -webkit-appearance: none;
  }
  .btn_logo {
    width: 13.8rem;
  }
  .btn_top,
  .btn_main_w,
  .btn_main_g {
    display: flex;
    color: white;
    position: relative;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
  }
  .btn_top:after,
  .btn_main_w:after,
  .btn_main_g:after {
    content: "";
    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .btn_top,
  .btn_main_g {
    font-weight: bold;
  }
  .btn_main_w {
    background: white;
    border: 1px solid #008879;
    font-weight: bold;
  }
  .btn_top:after,
  .btn_main_g:after {
    background-image: url(../images/new-lp/icon_right_white.svg);
  }
  .btn_main_w:after {
    background-image: url(../images/new-lp/icon_right_grey.svg);
  }
  .btn_top {
    width: 24.8rem;
    line-height: 4.8rem;
    font-size: 1.4rem;
    border-radius: 2.4rem;
  }
  .btn_top:after,
  .btn_main_g:after,
  .btn_main_w:after {
    width: 1.42rem;
    height: 0.835rem;
    margin-left: 1.2rem;
    top: -0.1rem;
  }
  .btn_main_g,
  .btn_main_w {
    //width: 29.2rem;
    width: 28.5rem;
    //line-height: 6.4rem;
    line-height: 6.2rem;
    border-radius: 3.2rem;
  }
  .btn_nav {
    width: 24.3rem;
    line-height: 6.1rem;
    border-radius: 3.05rem;
    background: white;
    padding-right: 5rem;
    text-align: center;
    position: relative;
    display: block;
    padding-right: 5rem;
    border: 1px solid transparent;
  }
  .btn_nav i {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    right: 3.6rem;
    top: calc(50% - 0.75rem);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
  .btn_payment,
  .btn_voice {
    font-size: 1.2rem;
    text-align: center;
  }
  .btn_voice span i {
    display: none;
  }
  .btn_payment i {
    margin-left: 2.4rem;
    display: inline-block;
    font-size: 1rem;
  }
  .btn_accordion {
    cursor: pointer;
  }



