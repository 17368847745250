﻿body {
  position: relative;
  margin: 0;
}

h2 {
  font-size: 3.2rem;
  text-align: center;
  letter-spacing: 0.08rem;
}

p,
td,
label,
div,
ul,
li,
strong {
  font-size: 1.6rem;
  margin: 0;
}

p {
  white-space: pre-line;
}

main {
  position: relative;
}

h2.hanaravi {
  font-size: 1.8rem;
}

.accordionDetail {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s linear;
}

.accordion .btn_accordion .close {
  display: none;
}

.accordion.opened .accordionDetail {
  display: block;
  max-height: 50rem;
}

.accordion.opened .btn_accordion .close {
  display: inline-block;
}

.accordion.opened .btn_accordion .open {
  display: none;
}

.globBanner {
  display: none;
}

.title2 {
  text-align: center;

  h2,
  h3 {
    @include serif();
    font-size: 32px;
    font-weight: 400;

    @media #{$sp} {
      font-size: 20px;
    }
  }
}

.title2 {
  @media #{$sp} {
    line-height: 1.6;
  }

  small {
    font-size: 14px;

    @media #{$sp} {
      font-size: 11px;
    }
  }
}