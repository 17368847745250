.planItemInfoPrice {
  color: #333333;
}

.green,
.reasonItemInfo label,
.planItemTitle h3,
.planItemInfoPrice span,
.banner h3,
.paymentConclusion a,
.pattern h3,
.voiceMentionTxt p:before,
.voiceMentionTxt p:after,
.clinicItemInfo h3,
.faqList dt:before,
.faqList dd span:before,
.contactInfo h2,
.globFooterNns a,
.btn_main_w,
.btn_nav,
.btn_payment,
.btn_voic,
.globFooterNav ul li a i,
.globNaviMenu ul li a i {
  color: #008879;
}

.whatisinside {
  color: rgb(82, 207, 169);
}

.icon_check i,
.flowItem span,
.globNaviIcon span,
.btn_nav i {
  background-color: #008879;
}

.btn_clinic {
  background-color: #07b53b;
}

.btn_top,
.btn_main_g {
  background-color: #07b53b;
}
.btn_top,
.btn_main_g {
  background-color: #07b53b;
}

.btn_top_dark,
.btn_main_g_dark {
  background-color: #008879;
}

.btn_line {
  color: white;
  // color: #6A6A6A;
  font-weight: bold;
}

// .reasonItemMediaWi .reasonItemMediaItemInfo label i {
//   background-color: #6A6A6A;
// }

.btn_nav i {
  color: white;
}

.btn_main_w {
  /*border: 1px solid #008879; */
  border: 1px solid #008879;
}
