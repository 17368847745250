.l_con,
.m_con {
  width: 120rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 20;
  position: relative;
}

.m_con {
  width: 104.2rem;
}

.sCom {
  padding: 10rem 0;
}

.sBody {
  margin-top: 7rem;
}

.fv {
  position: relative;
}

.fvImg {
  height: 65rem;
  object-fit: cover;
}

.fvInfo {
  position: absolute;
  left: calc(50% - 54rem);
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.fvInfo h1 {
  font-size: 3.7rem;
  line-height: 1.5;
  margin-top: 2rem;
  font-weight: bold;
}

.fvInfo p.bold {
  font-weight: bold;
}

.fvInfo img {
  width: 24.46rem;
  margin-top: 5.8rem;
}

.fvInfo h4 {
  font-size: 2.2rem;
  margin-top: 2rem;
  letter-spacing: 0.12rem;
  font-weight: bold;
}

// .fvInfo p {
//   font-size: 2rem;
//   margin-top: 2rem;
//   letter-spacing: 0.12rem;
// }
// .fvInfo .btn {
//   margin-top: 2rem;
// }
// .sponsor {
//   padding: 3.6rem 0;
// }
// .sponsorImg {
//   padding: 1.5rem 0;
//   width: 69.1rem;
//   margin: auto;
// }
.pageNavList {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.pageNavList li {
  display: block;
  margin: 0 0.8rem;
}

.pageNav {
  padding: 2rem;
}

.reason {
  position: relative;
  overflow: hidden;
}

.reason:before {
  content: "";
  left: calc(50% - 96rem);
  top: 0;
  bottom: 0;
  width: 192rem;
  position: absolute;
  background-image: url(../images/new-lp/bg1.png);
  background-size: 100%;
  background-position: top center;
  background-color: #fafafa;
}

.reason .l_con {
  width: 96rem;
}

.reasonList {
  display: block;
  list-style: none;
}

.reasonItemMedia {
  width: 51.5rem;
  border-radius: 1.6rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.reasonItemMedia2 {
  padding: 0 24px;
}

.reasonItemMedia2 img {
}

.reasonItemMedia img {
  object-fit: contain;
}

.reasonItemInfo {
  width: 36rem;
}

.reasonItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reasonItemReserve {
  flex-direction: row-reverse;
}

.reasonItemInfo label {
  font-size: 1.7rem;
  letter-spacing: 0.06rem;
  font-family: "futura medium italic";
}

.reasonItemInfo label span {
  font-size: 4rem;
  margin-left: 1.2rem;
  letter-spacing: 0.1rem;
  letter-spacing: 0.08rem;
}

.reasonItemInfo h3 {
  font-size: 2.4rem;
  line-height: 1.5;
  margin-top: 1.2rem;
  letter-spacing: 0.08rem;
}

.reasonItemInfo p {
  margin-top: 3.2rem;
  letter-spacing: 0.02rem;
}

.reasonItemInfo p span {
  font-size: 1.3rem;
  letter-spacing: 0.02rem;
}

.reasonList > li:not(:first-child) {
  margin-top: 10rem;
}

// .reasonItemMediaImgs {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.reasonItemMediaItem {
  width: 50%;
  background: white;
}

// .reasonItemMediaItemInfo p {
//   font-size: 1.6rem;
// }
// .reasonItemMediaItemInfo p span {
//   font-size: 2em;
// }
// .reasonItemMediaItemInfo {
//   padding: 3.2rem 2.4rem;
// }
// .reasonItemMedia>.reasonItemMediaItem:not(:first-child) .reasonItemMediaItemInfo {
//   border-left: 1px solid #EFEFEF;
// }
// .reasonItemMediaItemInfo label {
//   font-size: 1.8rem;
//   margin-top: 1rem;
//   display: block;
// }
.icon_check i {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  line-height: 1.6rem;
  color: white;
  text-align: center;
  font-size: 1rem;
  position: relative;
  margin-right: 0.8rem;
  background-image: url(../images/new-lp/icon_check.svg);
  background-size: 0.75rem;
  background-repeat: no-repeat;
  background-position: center;
  top: 0.2rem;
}

.reasonItemMediaItemImg {
  position: relative;
}

// .reasonItemMediaItemImg p {
//   font-size: 1.6rem;
//   font-weight: bold;
//   color: white;
//   text-align: center;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   padding-top: 9.8rem;
//   background: rgba(126,126,126,0.2);
//   position: absolute;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .reasonItemMediaItemImg p img {
//   width: 3.6rem;
//   height: 3.6rem;
//   object-fit: contain;
//   position: relative;
//   line-height: inherit;
//   margin-right: 1rem;
//   display: block;
// }
.secAct {
  margin-top: 9rem;
}

.secAct .btn {
  margin: auto;
}

.ttlDesc {
  font-size: 1.8rem;
  text-align: center;
  margin-top: 2.8rem;
  line-height: 2.8rem;
}

.flowItem {
  width: calc(100% / 3 - 7rem);
  margin: 3rem 3.5rem;
  position: relative;
}

.flowList {
  margin: -3rem -3.5rem;
  width: calc(100% + 7rem);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  list-style: none;
}

.flowItemImg {
  border-radius: 1.6rem;
}

.flowItem h3 {
  font-size: 2.4rem;
  letter-spacing: 0.08rem;
  margin-top: 2rem;
}

.flowItem p {
  letter-spacing: 0.02rem;
  margin-top: 2rem;
}

.flowItem span {
  width: 3.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  color: white;
  text-align: center;
  position: absolute;
  display: block;
  left: 2rem;
  top: -1.6rem;
  border-radius: 50%;
  font-family: "futura medium";
  font-size: 1.6rem;
}

.plan {
  background: url(../images/new-images/bg2.jpg);
  background-size: cover;
  background-position: center;
}

.planList {
  margin: -2rem;
  width: calc(100% + 4rem);
  display: flex;
  justify-content: center;
}

.planItem {
  margin: 2rem;
  width: calc(100% / 3 - 4rem);
  background: white;
  border-radius: 1.6rem;
  overflow: hidden;
}

.planItemTitle {
  padding: 2rem 2.4rem;
}

.planItemTitle h3 {
  font-family: "futura medium italic";
  font-size: 2rem;
}

.planItemTitle p {
  font-size: 1.4rem;
  margin-top: 0.5rem;
  font-family: "futura medium italic";
}

.planItemInfo {
  padding: 1.6rem 2.4rem;
}

.planItemInfoPrice {
  font-size: 1.8rem;
}

.planItemInfoPrice span {
  font-size: 4rem;
}

.planItemInfoOne {
  font-size: 1.8rem;
  margin-top: 1rem;
}

.planItemInfoOne span{
  font-size: 2.5rem;
}

.sBody p{
  font-size: 1.2rem;
}

.planItemInfoPeriod {
  margin-top: 2rem;
  font-size: 1.5rem;
}

.banner {
  padding: 3rem 2.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1.2rem;
  overflow: hidden;
  margin-top: 4rem;
}

.banner h3 {
  width: 24.7rem;
  line-height: 5.8rem;
  border-radius: 2.9rem;
  background: white;
  font-size: 1.8rem;
  text-align: center;
  font-family: "futura medium italic";
  margin-right: 5.3rem;
}

.planSupport ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 30rem);
  list-style: none;
}

.planSupport ul li {
  width: 32.2rem;
  font-size: 1.4rem;
  font-family: "futura medium italic";
  line-height: 2.5;
}

.planSupport ul li i {
  margin-right: 1.2rem;
}

// .planSupport p{
//   font-size: 1.3rem;
//   font-family: "futura medium italic";
//   display: flex;

// }

.paymentInfo {
  width: 80rem;
  background: white;
  padding: 2.4rem 0.6rem 1.2rem;
  border-radius: 1.6rem;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.paymentInfo .paymentItemTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.7rem;
  width: 100%;
}

.paymentItemTitle h3 {
  font-size: 1.4rem;
  letter-spacing: 0.04rem;
}

.paymentItem {
  width: 25%;
}

.paymentList > li:not(:first-child) .paymentItemTitle {
  border-left: 1px solid #dddddd;
}

.paymentItemTitlemark {
  height: 3.3rem;
  object-fit: contain;
  margin-right: 1.2rem;
  width: initial;
}

.paymentItem p span {
  margin-top: 2.2rem;
  line-height: 2;
  font-size: 1.3rem;
  padding: 0 1.2rem;
  display: block;
}

.paymentList {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
}

.paymentAct {
  text-align: center;
  margin-top: 2.4rem;
}

.paymentConclusion {
  margin-top: 4rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.14;
}

.paymentConclusion a {
  text-decoration: underline;
  color: inherit;
}

// .paymentInfo.opened .paymentItem>p {
//   max-height: 20rem;
// }
.alignmentSec {
  margin-top: 5.5rem;
}

.patternItem {
  width: 17.5rem;
  margin: 3.4rem 3rem;
}

.patternItem p {
  margin-top: 1.5rem;
  text-align: center;
}

.patternList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3rem;
  list-style: none;
}

.pattern {
  padding: 5rem 4rem 6.8rem;
  background: white;
  border-radius: 1.6rem;
  overflow: hidden;
  background: white;
}

.pattern h3 {
  font-size: 2.4rem;
  text-align: center;
}

.alignmentSec {
  text-align: center;
}

.alignmentSec .btn {
  margin: auto;
}

.alignment:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fafafa;
  z-index: -1;
}

.patternDesc {
  width: 75rem;
  margin: 5rem auto 7rem;
  font-size: 1.3rem;
  letter-spacing: 0.117rem;
}

.hwDesc {
  width: 75rem;
  margin: 5rem auto 0rem;
  font-size: 1.2rem;
  letter-spacing: 0.117rem;
}


.hwDesc p{
  font-size: 1.2rem;
}


.hwDesc ul{
  margin: 0em 1em;
  font-size: 1.2rem;
  letter-spacing: 0.117rem;
}

.hwDesc li{
  font-size: 1.2rem;
  overflow-wrap: break-word;
}

.ul-flex{
  display: flex;
  justify-content: center;
  gap: 0px 20px;
}
@media screen and (max-width: 767px) {
  .hwDesc ul{
    width: 30rem;
    margin: 0em 1em;
    font-size: 1.2rem;
    letter-spacing: 0.117rem;
  } 
}


.applyBannerInfo {
  width: 46.5rem;
  margin-right: 5rem;
}

.applyBannerInfo p {
  font-size: 2.8rem;
}

.applyBannerInfo sup {
  font-size: xx-small;
  vertical-align: top;;
}

.applyBannerInfo label {
  margin-top: 1.4rem;
  display: block;
}

.applyBannerImg {
  width: 12.1rem;
  height: 12.1rem;
  border: 0.85rem solid white;
  border-radius: 50%;
}

.voiceInfo {
  width: 81.1rem;
  border-radius: 1.6rem;
  overflow: hidden;
  padding: 5rem;
  margin-left: auto;
  margin-right: auto;
  background: white;
}

.voiceDetail {
  position: relative;
  border-radius: 1.2rem;
  padding: 3.6rem 3rem 2rem;
  margin-top: 2.6rem;
}

.voiceDetail:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 1.5rem solid transparent;
  border-right: 1.5rem solid transparent;
  border-bottom: 1.2rem solid #fff6e8;
  position: absolute;
  right: 10rem;
  top: -1.1rem;
}

.voiceAct {
  margin-top: 2rem;
  text-align: right;
}

// .voiceAct .btn_voice .opened {
//   display: none;
// }
.voiceMentionImg {
  width: 15.2rem;
  object-fit: contain;
  height: 15.2rem;
  border-radius: 50%;
}

.voiceMention {
  width: 64.3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.voiceMentionTxt {
  width: 47.5rem;
  position: relative;
}

.voiceMentionTxt p {
  padding: 2rem 5.5rem;
  color: #333333;
  font-size: 2rem;
  line-height: 2;
  font-family: "TsukuBRdGothic";
  color: #333333;
}

.voiceMentionTxt p:before,
.voiceMentionTxt p:after {
  position: absolute;
  font-size: 4rem;
  line-height: 1;
  font-family: "azuki_1";
}

.voiceMentionTxt p:before {
  content: "“";
  left: 0;
  top: 2rem;
}

.voiceMentionTxt p:after {
  content: "”";
  right: 0;
  bottom: 1rem;
}

.voiceMentionTxt label {
  font-size: 1.1rem;
  text-align: center;
  display: block;
}

.voiceMentionTxt label span {
  font-size: 1.3rem;
}

.bg3 {
  position: relative;
  overflow: hidden;
}

.bg3:before {
  content: "";
  background-image: url(../images/new-lp/bg3.png);
  background-size: 100%;
  background-position: top center;
  position: absolute;
  left: calc(50% - 96rem);
  top: 0;
  width: 192rem;
  height: 79.1rem;
}

.clinic {
  padding-top: 7.3rem;
}

.clinicItem {
  border-radius: 1.6rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background: white;
}

.clinicImg {
  width: 39.2rem;
  object-fit: contain;
}

.clinicItemInfo {
  width: calc(100% - 39.2rem);
  padding: 4.5rem 5rem;
}

.clinicItemInfo h3 {
  font-size: 2.4rem;
}

.clinicItemInfo p {
  margin-top: 1.5rem;
  line-height: 2.9rem;
}

.clinicItemInfoRef li {
  font-size: 1.4rem;
  color: #888888;
  list-style: none;
  line-height: 1.8;
}

.clinicItemInfoRef {
  margin-top: 1.5rem;
}

.clinicItemInfoRef li i {
  color: #e0dadf;
  margin-right: 1rem;
}

.clinicList > li:not(:first-child) {
  margin-top: 3.7rem;
}

.thinkDetailList li {
  padding-left: 3.2rem;
  line-height: 2;
  display: block;
  list-style: none;
}

.thinkDetailList {
  padding: 2.4rem;
  border-radius: 1.2rem;
  margin-top: 5rem;
  width: 60rem;
}

.thinkDetailList li {
  position: relative;
}

.thinkDetailList li:before {
  content: "";
  background: url(../images/new-lp//icon_heart.svg);
  width: 1.8rem;
  height: 1.7rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0.5rem;
}

.thinkInfo h3 {
  font-size: 2rem;
  line-height: 1.6;
}

.thinkInfo > p {
  line-height: 2;
  margin-top: 3.6rem;
}

.thinkInfo > h3:not(:first-child) {
  margin-top: 7rem;
}

.thinkInfo {
  width: 90rem;
  margin: auto;
  background: white;
  border-radius: 1.6rem;
  padding: 6rem;
}

.thinkMan {
  position: relative;
  padding-bottom: 2rem;
}

.thinkManImg {
  width: 34.6rem;
  margin-right: 6.7rem;
  margin-left: auto;
}

.thinkFlower {
  width: 32.6rem;
  position: absolute;
  left: 0;
  top: -12rem;
  object-fit: contain;
}

// .thinkManName {
//   position: absolute;
//   right: 0;
//   bottom: 10rem;
// }
.thinkManInfo {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 4rem;
}

.thinkManInfo p {
  font-size: 1.4rem;
}

.thinkManInfo img {
  width: 10rem;
  margin-top: 1rem;
}

.think:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fafafa;
  z-index: -1;
}

.life {
  position: relative;
}

.life h2 {
  font-size: 5.3rem;
  font-family: "futura medium italic";
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0;
}

.life h2 .lg {
  font-size: 1.02em;
}

.life h2 img {
  width: 22.2rem;
  margin-right: 2.5rem;
}

.life p {
  margin-top: 4rem;
  text-align: center;
}

.life:after {
  content: "";
  background: url(../images/new-lp//bg4.png);
  background-size: 100%;
  background-position: center;
  top: -15rem;
  left: calc(50% - 40.95rem);
  bottom: -15rem;
  position: absolute;
  width: 81.9rem;
}

.faqList dt {
  border-top: 1px solid #e4e4e4;
  padding: 3.5rem 4rem 3.5rem 5.6rem;
  font-size: 1.8rem;
  position: relative;
  cursor: pointer;
}

.faqList dd {
  position: relative;
}

.faqList dd span {
  display: block;
  background: #fafafa;
  border-radius: 2rem;
  padding: 3rem 4rem 3.5rem 5.6rem;
  position: relative;
  margin-top: 2.45rem;
  margin-bottom: 3rem;
}

.faqList dt:before,
.faqList dd span:before {
  position: absolute;
  left: 2rem;
  font-family: "IvyJournal Regular";
  font-size: 2rem;
  line-height: 1;
}

.faqList dt:after {
  content: "";
  background-image: url(../images/new-lp/icon_plus.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 3rem;
  width: 2rem;
  height: 2rem;
  top: calc(50% - 1rem);
}

.faqList dt:before {
  content: "Q.";
  top: 3.7rem;
}

.faqList dd span:before {
  top: 3rem;
  content: "A.";
}

.faqList > dl:last-child dt,
.faqList dl.opened dt {
  border-bottom: 1px solid #e4e4e4;
}

.faqList dl.opened dt:after {
  background-image: url(../images/new-lp/icon_minus.svg);
}

.youtube_wrap {
  width: 560px;
  margin: auto;
}

.youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin: 20px auto;
}

.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 560px) {
  .youtube_wrap {
    width: 100%;
  }
}

.contact {
  position: relative;
}

.contactImg {
  height: 42rem;
  object-fit: cover;
}

.contactInfo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.contactInfo h2 {
  font-size: 4rem;
  letter-spacing: 0.08rem;
}

.contactInfo p {
  font-size: 1.8rem;
  margin-top: 2.5rem;
}

.contactAct {
  display: flex;
  justify-content: flex-start;
  margin-top: 6rem;
}

.contactAct .btn {
  margin-right: 2rem;
}

// .preparation_set_img {
//   padding-top: 15px;
//   .reparation_img {
//     width: 35.2rem;
//     border: 2px solid #e4e4e4;
//   }
// }

// .line-consult-image {
//   padding-top: 25px;
//   .inner-image  {
//     width: 35.2rem;
//   }
// }

section.logos {
  padding: 60px 0;
}

section.logos img {
  max-width: 610px;
  margin: auto;
}

sub {
  font-size: 1.2rem;
}