﻿  @charset "UTF-8";
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }
  html {
    line-height: 2;
  }
  // table {
  //   border-collapse: collapse;
  //   border-spacing: 0;
  // }

  // caption, th, td {
  //   text-align: left;
  //   font-weight: 500;
  //   vertical-align: middle;
  // }

  // q, blockquote {
  //   quotes: none;
  // }
  // q:before, q:after, blockquote:before, blockquote:after {
  //   content: "";
  //   content: none;
  //   display: inline-block;
  //   font-style: normal;
  //   font-variant: normal;
  //   text-rendering: auto;
  //   -webkit-font-smoothing: antialiased;
  // }

  a img {
    border: none;
  }

  article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
  }

  *, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  html, body {
    color: #6A6A6A;
    font-family: "游ゴシック Medium", "游ゴシック体", "游ゴシック", "ヒラギノ丸ゴ Pro W4", "ヒラギノ角ゴシック", "Yu Gothic UI", "Yu Gothic Medium", "YuGothic", "YuGothicPr6NM", "MS Pゴシック", "MSゴシック", "ヒラギノ角ゴ Pro W3", "ヒラギノ角ゴ ProN", "ヒラギノ角ゴ Pro", "Hiragino Maru Gothic ProN W4","ヒラギノ丸ゴ ProN W4","Hiragino Maru Gothic Pro W4", "メイリオ", sans-serif;
    line-height: 1.5;
    text-align: justify;
    -webkit-text-size-adjust: 100%;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.04rem;
  }

  a,a:hover {
    text-decoration: none;
  }

  img {
    display: block;
    width: 100%;
  }

  h1, h2, h3, h4, h5,  h6, p {
    margin: 0;
  }

  p, dt, dd {
    overflow-wrap: break-word;
  }

  body {
    margin: auto;
    position: relative;
  }

