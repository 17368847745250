﻿@charset "utf-8";

//---------------------------------------------------------
// breakpoint
//---------------------------------------------------------
$sp: "only screen and (max-width:414px)";
$tablet-v: "only screen and (max-width:768px)";
$tablet-h: "only screen and (max-width:1024px)";

//---------------------------------------------------------
// color
//---------------------------------------------------------
$white: #fff;
$black: #000;
$green: #32c332;
$gray1: #ccc;
$gray2: #f4f4f4;
$grad1: linear-gradient(to right bottom, #f5d1bb 0%, #fde3d3 100%);
$grad2: linear-gradient(to right bottom, #4e73c4 0%, #8ba9eb 100%);
$beige1: #f2c9b1;
$beige2: #faf6f2;
$lavender1: #4e73c4;
$lavender2: #9cb9f6;
$lavender3: #e1e7f6;

//---------------------------------------------------------
// mixin
//---------------------------------------------------------
@mixin serif() {
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "Noto Serif JP", sans-serif;
  font-weight: 400;
}

@mixin sans() {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "游ゴシック Medium", "Yu Gothic Medium", YuGothic, -apple-system, BlinkMacSystemFont, meiryo, sans-serif;
  font-weight: 400;
}

@mixin trajan() {
  font-family: trajan-pro-3, sans-serif;
  font-weight: 400;
}

@mixin flex() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin boxshadow() {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

//---------------------------------------------------------
// import
//---------------------------------------------------------
// @import "thanks";
@import "footer";
// @import "error";
@import "header";
@import "reset";
// @import "animation";
@import "button";
@import "color";
@import "font";
@import "content";
@import "common";
@import "effect";
// @import "responsive";
