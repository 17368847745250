.header {
  border-bottom:1px solid #EAEAEA;
}

.globNaviCheck,
.globNaviIcon {
  display: none;
}

.headerControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
}

.headerControls .btn_logo {
  order: 1;
}

.globNavi {
  order: 2;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerControls .btn_top {
  order: 3;
}

.globNaviMenu ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.globNaviMenu ul li {
  display: block;
  margin: 0 1.5rem;
}
.globNaviMenu ul li a {
  font-size: 1.4rem;
  display: block;
  color: #6A6A6A;
}
.globNaviMenu {
  margin-left: auto;
  margin-right: 1.5rem;
}
.btn_flogo {
  width: 18rem;
}
.globFooterNns {
  display: flex;
  justify-content: center;
  align-items: center;
}
.globFooterNns a {
  display: block;
  font-size: 3rem;
  margin-left: 1.9rem;
}
.globFooterNav {
  padding-left: 6rem;
  flex-grow: 1;
}
.globFooterNav ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
  margin-left: 0;
}
.globFooterNav ul li {
  display: block;
  margin: 0 2rem;
}
.globFooterNav ul li a {
  font-size: 1.5rem;
  display: block;
  color: #6A6A6A;
}
.globFooter .l_con {
  height: 17rem;
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.globInfoNav ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.globInfoNav li {
  display: block;
  margin-right: 3.2rem;
}
.globInfoNav a {
  font-size: 1.4rem;
  display: block;
  color: #6A6A6A;
}
.globInfo {
  padding: 2.5rem 0;
  background: #FAFAFA;
}
.globInfo .l_con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.globFooterNav ul li a i,
.globNaviMenu ul li a i {
  display: none;
}
.copyright {
  font-size: 1.3rem;
}

// .sp_divider {
//   order: 4;
//   flex-grow: 1;
//   display: none;
// }

// .sp_tel_area {
//   display: none;
//   order: 5;
//   & .tel_desc {
//     font-size: 0.95rem;
//   }

//   & .tel_number {
//     color: #008879;
//     font-weight: bold;
//   }
// }