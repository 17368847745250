.btn_main_g,
.btn_top,
.btn_main_w,
.btn_main_g,
.btn_nav,
.reasonItemMedia,
.flowItemImg,
.planItem,
.banner,
.paymentInfo,
.pattern,
.clinicItem,
.btn_line,
.btn_clinic,
.voiceInfo,
.thinkInfo,
.btn_green {
  box-shadow: 0 0.1rem 0.6rem rgba(12, 74, 72, 0.1);
}

.planItemTitle,
.pageNav,
.banner,
.payment,
.voiceDetail,
.thinkDetailList,
.life,
.globBanner {
  background: rgb(232, 255, 252);
  background: linear-gradient(
    117deg,
    rgba(232, 255, 252, 1) 0%,
    rgba(255, 246, 232, 1) 100%
  );
}

.vc:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgb(232, 255, 252);
  background: linear-gradient(
    160deg,
    rgba(232, 255, 252, 1) 0%,
    rgba(255, 246, 232, 1) 100%
  );
  z-index: -1;
}

.btn_top,
.btn_main_w,
.btn_main_g,
.btn_nav,
.btn_green {
  transition: all 0.3s ease-out;
}

// .btn_green:hover {
//   background: white;
//   border: 1px solid #008879;
//   color: #008879;
// }

// .btn_top:hover,
// .btn_main_g:hover {
//   background: white;
//   border: 1px solid #07B53B;
//   color: #07B53B;
// }

// .btn_main_w:hover,
// .btn_nav:hover {
//   background: #008879;
//   border-color: transparent;
//   color: white;
// }

// .btn_nav:hover i {
//   color: #008879;
//   background: white;
// }

// .btn_top:hover:after,
// .btn_main_g:hover:after,
// .btn_green :hover:after {
//   background-image: url(../images/new-lp/icon_right_grey.svg);
// }

// .btn_main_w:hover:after {
//   background-image: url(../images/new-lp/icon_right_white.svg);
// }

.pc {
  display: block !important;
}

.sp {
  display: none !important;
}

// .sp_btn {
//   padding-left: 26px;
// }

// .fvComment {
//   display: none;
// }

@media screen and (max-width: 1250px) {
  html,
  body {
    font-size: calc(100vw / 125);
  }

  // .sp_btn {
  //   padding-left: 19.4px;
  // }
}

@media screen and (max-width: 767px) {
  // .sp_btn {
  //   padding-top: 15px;
  //   padding-left: 28.8px;
  // }

  // .spFrame {
  //   padding-top: 20px;
  // }

  .sp {
    display: block !important;
  }

  .pc {
    display: none !important;
  }

  html,
  body {
    font-size: calc(100vw / 41.4);
  }

  p,
  h4,
  td,
  label,
  div,
  ul,
  li,
  strong {
    font-size: 1.5rem;
  }

  .m_con,
  .l_con,
  .reason .l_con {
    width: calc(100% - 7.2rem);
  }

  .sBody {
    margin-top: 4rem;
  }

  .sCom {
    padding: 5.4rem 0;
  }

  .globBanner {
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    bottom: 0;
    height: 6.2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .globBanner .btn {
    margin: 0 0.5rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  /*------------------button-----------------------*/
  .btn_logo {
    width: 9.6rem;
  }

  // .btn_top {
  //   width: 21rem;
  //   line-height: 3rem;
  //   font-size: 1.2rem;
  //   border-radius: 1.5rem;
  //   margin-left: 2rem;
  //   display: none;
  // }

  .btn_main_g,
  .btn_main_w {
    width: 27.5rem;
    line-height: 5.5rem;
    font-size: 1.5rem;
  }

  .btn_main_g:after,
  .btn_main_w:after {
    width: 1.6rem;
    height: 0.942rem;
    margin-left: 1rem;
  }

  .btn_nav {
    width: 12.6rem;
    line-height: 5.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    font-size: 1.3rem;
    background: #fafafa;
  }

  .btn_nav i {
    width: 1.4rem;
    height: 1.4rem;
    left: calc(50% - 0.7rem);
    top: auto;
    font-size: 0.9rem;
    bottom: 0.6rem;
    background: white;
    color: #008879;
  }

  .btn_voice span i {
    display: inline-block;
    margin-left: 2rem;
  }

  .btn_flogo {
    width: 15.4rem;
  }

  .btn_clinic,
  .btn_line {
    width: 19rem;
    height: 4.5rem;
    border-radius: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  // .btn_clinic img,
  // .btn_line img {
  //   width: 2.6rem;
  //   margin-right: 1.4rem;
  // }

  .btn_clinic {
    color: white;
    border: 1px solid;
  }

  .btn_line {
    // background: #F7F7F7;
    background: #ffaa19;
    border: 1px solid;
  }

  /*------------------header-----------------------*/
  .header {
    position: relative;
    z-index: 100;
  }

  .headerControls {
    height: 4.8rem;
    width: 100%;
    padding: 0 2.4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .globNavi {
    display: none;
    position: fixed;
    top: 1.1rem;
    right: 0.9rem;
    width: 3.5rem;
    height: 2.5rem;
    -webkit-user-select: none;
    z-index: 100;
  }

  // .globNaviCheck {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   top: 0;
  //   opacity: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 1001;
  //   -webkit-touch-callout: none;
  //   cursor: pointer;
  //   display: block;
  //   margin: 0;
  // }

  // .globNaviIcon {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   flex-direction: column;
  //   position: relative;
  //   z-index: 1000;
  //   width: 100%;
  //   background: none;
  //   padding: 0;
  // }

  // .globNaviIcon>span {
  //   height: 0.2rem;
  //   display: block;
  //   width: 100%;
  //   transform-origin: 0 0;
  //   transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
  // }

  // .globNaviIcon>span:nth-child(3) {
  //   transform-origin: 100% 0;
  // }

  // .globNaviIcon>span:not(:first-child) {
  //   margin-top: 1rem;
  // }

  // .globNaviCheck:checked~.globNaviIcon span {
  //   opacity: 1;
  //   transform: rotate(45deg) translate(-0.3rem, -0.3rem);
  // }

  // .globNaviCheck:checked~.globNaviIcon>span:nth-child(2) {
  //   transform: scale(0, 0);
  // }

  // .globNaviCheck:checked~.globNaviIcon>span:nth-child(3) {
  //   transform: rotate(-45deg) translate(0.6rem, -2rem);
  //   width: 100%;
  //   margin-left: 0;
  // }

  // .globNaviIcon span {
  //   text-decoration: none;
  //   overflow: hidden;
  // }

  // .globNaviCheck:hover~.globNaviIcon span:after {
  //   /*animation: 1s linear 0s infinite running move_eye;*/
  // }

  // @-webkit-keyframes move_eye {
  //   from {
  //     transform: translate(-100%, 0);
  //   }

  //   to {
  //     transform: translate(0, 0);
  //   }
  // }

  // @keyframes move_eye {
  //   from {
  //     transform: translate(-100%, 0);
  //   }

  //   to {
  //     transform: translate(0, 0);
  //   }
  // }

  // /*
  //  * Make this absolute positioned
  //  * at the top left of the screen
  //  */
  // .globNaviMenu {
  //   position: fixed;
  //   margin: 0;
  //   padding: 4rem 1.5rem;
  //   width: 100%;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   z-index: 10;
  //   overflow: auto;
  //   background: white;
  //   -webkit-font-smoothing: antialiased;
  //   transform-origin: 0% 0%;
  //   transform: translate(100%, 0);
  //   transition: transform 0.1s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  //   left: 0;
  //   right: 0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-direction: column;
  // }

  // .globNaviCheck:checked~.globNaviMenu {
  //   transform: none;
  //   z-index: 60;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  // .globNaviCheck:checked~.globNaviIcon span {
  //   transform: rotate(45deg) translate(0.5rem, -0.5rem);
  // }

  // .globNaviCheck:checked~.globNaviIcon>span:nth-child(3) {
  //   transform: rotate(-45deg) translate(1.5rem, -2rem);
  // }

  // .globNaviMenu ul {
  //   display: block;
  //   width: 100%;
  //   border: none;
  // }

  // .globNaviMenu {
  //   padding: 3.2rem 4rem;
  //   display: block;
  // }

  // .globNavi ul {
  //   display: block;
  //   height: auto;
  // }

  .globFooter .l_con {
    height: auto;
    padding: 4.2rem 0 2.8rem;
    display: block;
  }

  .globFooterNav {
    padding-left: 0;
    flex-grow: 1;
    margin-top: 4.3rem;
    display: block;
  }

  .globFooterNav ul,
  .globNaviMenu ul {
    display: block;
    border-top: 1px solid #e4e4e4;
  }

  .globFooterNav ul li,
  .globNaviMenu ul li {
    margin: 0;
  }

  .globFooterNav ul li a,
  .globNaviMenu ul li a {
    margin: 0;
    line-height: 4.7rem;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .globFooterNav ul li a i,
  .globNaviMenu ul li a i {
    display: inline;
    font-size: 0.6em;
  }

  .globFooterNns {
    justify-content: flex-start;
    margin-top: 2rem;
  }

  .globFooterNns a {
    margin-right: 1.9rem;
    margin-left: 0;
  }

  .globInfo .l_con {
    display: block;
    margin-bottom: 8rem;
  }

  .globInfo {
    padding: 2.2rem 0;
  }

  .globInfoNav a {
    font-size: 1.15rem;
  }

  .globInfoNav ul > li {
    margin-right: 0;
    position: relative;
  }

  .globInfoNav ul > li:not(:first-child) {
    margin-left: 1.6rem;
  }

  .globInfoNav ul > li:not(:first-child):before {
    content: "";
    width: 1px;
    height: 1.2rem;
    background: #e4e4e4;
    position: absolute;
    left: -0.8rem;
    top: calc(50% - 0.6rem);
  }

  .copyright {
    font-size: 1rem;
    margin-top: 1.2rem;
  }

  /*-------------------page------------------*/
  .fvImg {
    // content: url(../images/new-lp/fv_top_campaign.jpg);
    content: url(../images/new-images/202309/sp_header.jpg);
    height: auto;
  }

  .fvInfo {
    display: none;
  }

  .fvButtonArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #008879;
    padding: 20px 16px 32px;
    .overPop {
      color: white;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
    }
    .fvButton {
      width: 100%;
      max-width: 480px;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: center;

      padding: 8px 24px;
      border-radius: 16px;
      font-weight: bold;

      background: rgb(203, 231, 228);
      background: linear-gradient(
        90deg,
        rgb(203, 231, 228) 0%,
        rgba(249, 249, 249, 1) 100%
      );
      box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.3);

      .fvButtonLabel {
        font-size: clamp(9px, 5vw, 18px);
        color: #4d4d4d;
        white-space: nowrap;
      }

      .fvButtonStrong {
        color: #008879;
        font-size: clamp(18px, 10vw, 36px);
        line-height: 130%;
        white-space: nowrap;
      }
    }
  }

  .fvBottomImg {
    width: 100%;
    margin: 8px 0 24px;
  }

  // .fvComment {
  //   display: block;
  //   text-align: left;

  //   margin-top: 4rem;

  //   margin-left: 2rem;
  //   margin-right: 2rem;
  //   margin-bottom: 1rem;
  // }

  // .fvComment p {
  //   font-size: 0.6rem;
  // }

  // .sponsor {
  //   padding: 2.1rem 0;
  //   //margin-top: -2.8rem;
  // }

  // .sponsorImg {
  //   width: 38.3rem;
  // }

  .pageNavList li {
    margin: 0 0.25rem;
  }

  .reason:before {
    left: calc(50% - 20.7rem);
    width: 100%;
  }

  .reasonItem {
    display: block;
  }

  .reasonItemMedia {
    width: 100%;
  }

  .reasonItemInfo {
    margin-top: -3rem;
  }

  .reasonItemInfo label {
    font-size: 1.6rem;
    text-align: center;
    display: block;
  }

  .reasonItemInfo label span {
    font-size: 3.2rem;
    letter-spacing: 0.08rem;
    position: relative;
    margin-left: 1rem;
    top: 0.5rem;
  }

  .reasonItemInfo h3 {
    font-size: 2.2rem;
    line-height: 1.5;
    margin-top: 1rem;
    text-align: center;
  }

  .reasonItemInfo p {
    margin-top: 2rem;
  }

  .reasonList > li:not(:first-child) {
    margin-top: 3.4rem;
  }

  // .reasonItemMediaItemImg p {
  //   font-size: 1.4rem;
  //   padding-top: 7rem;
  // }

  // .reasonItemMediaItemImg p img {
  //   width: 2.4rem;
  //   height: 2.4rem;
  //   margin-right: 0.7rem;
  // }

  // .reasonItemMediaItemInfo {
  //   padding: 1.9rem 1.6rem 1rem;
  // }

  // .reasonItemMediaItemInfo p {
  //   font-size: 1.4rem;
  // }

  // .reasonItemMediaItemInfo p span {
  //   font-size: 2.2rem;
  // }

  // .reasonItemMediaItemInfo label {
  //   font-size: 1.4rem;
  // }

  .icon_check i {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    line-height: 1.2rem;
    top: 0.1rem;
  }

  .reasonItemMedia2 {
    padding-bottom: 3.5rem;
    padding-top: 2.5rem;
  }

  .reasonItemMedia2 img {
    width: 125%;
  }

  .secAct {
    margin-top: 4rem;
  }

  .ttlDesc {
    font-size: 1.5rem;
    margin-top: 3rem;
  }

  .flowList {
    margin: 0;
    width: 100%;
  }

  .flowItem {
    width: 100%;
    margin: 0;
  }

  .flowItem span {
    width: 3.6rem;
    height: 3.6rem;
    line-height: 3.6rem;
    left: 2.3rem;
    top: -1.8rem;
    font-size: 1.8rem;
  }

  .flowItem h3 {
    font-size: 2.2rem;
  }

  .flowList > li:not(:first-child) {
    margin-top: 5.2rem;
  }

  .plan {
    background: url(../images/new-images/bg2_sp.jpg);
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  }

  .plan .sBody {
    margin-top: 3rem;
  }

  .planList,
  .planItem {
    display: block;
    width: 100%;
    margin: 0;
  }

  .planItemTitle {
    padding: 2rem;
  }

  .planItemInfo {
    padding: 1.6rem;
  }

  .planList > li:not(:first-child) {
    margin-top: 2rem;
  }

  .banner {
    padding: 2.4rem 1.6rem;
    display: block;
  }

  .banner h3 {
    width: 24.2rem;
    line-height: 4rem;
    border-radius: 2rem;
    font-size: 1.4rem;
    margin: auto;
  }

  .planSupport ul {
    width: 100%;
    margin-top: 2rem;
  }

  .paymentInfo {
    width: 100%;
    padding: 0 0 2.4rem;
  }

  .paymentList {
    flex-wrap: wrap;
  }

  .paymentItem {
    width: 50%;
  }

  .paymentItemTitle {
    height: 6.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2.4rem;
  }

  .paymentItemTitle,
  .paymentInfo.opened .paymentItem > p {
    border-bottom: 1px solid #dddddd;
  }

  .paymentInfo .paymentItemTitle {
    height: 6rem;
    width: 100%;
    padding: 0;
  }

  .paymentItem > p {
    margin: 0;
    padding: 0;
  }

  .paymentList > li:nth-child(odd) .paymentItemTitle {
    border-left: none;
  }

  .paymentList > li:nth-child(even) > p {
    border-left: 1px solid #dddddd;
  }

  .paymentItemTitle h3 {
    font-size: 1.2rem;
  }

  .paymentItem > p span {
    padding: 1.2rem 1.2rem 2.4rem;
    display: block;
    font-size: 1.2rem;
    line-height: 1.68;
    margin: 0;
  }

  .paymentItemTitlemark {
    height: 2.1rem;
  }

  .paymentConclusion {
    margin-top: 2.8rem;
    font-size: 1.4rem;
  }

  .pattern {
    padding: 2.9rem 2.8rem;
  }

  .patternItem {
    width: calc(50% - 2.6rem);
    margin: 1.2rem 1.3rem;
  }

  .patternList {
    width: calc(100% + 2.6rem);
    margin: -1.2rem -1.3rem;
    margin-top: 2rem;
  }

  .patternItem p {
    font-size: 1.2rem;
  }

  .patternDesc {
    width: 100%;
  }

  .patternDesc {
    width: 100%;
    margin: 3rem auto 4rem;
    font-size: 1.2rem;
  }

  .patternDesc > span {
    display: block;
  }

  // .patternDesc>span:not(:first-child) {
  //   margin-top: 0rem;
  // }

  .applyBannerInfo {
    width: 100%;
    margin-top: 3.2rem;
  }

  .applyBannerInfo p {
    font-size: 1.8rem;
    text-align: center;
  }

  .applyBannerInfo label {
    margin-top: 2.3rem;
    display: block;
    text-align: center;
  }

  .applyBannerImg {
    margin: 2.4rem auto 0;
  }

  .voiceInfo {
    width: 100%;
    border-radius: 1.6rem;
    overflow: hidden;
    padding: 1.2rem 1.6rem 2.4rem;
  }

  .voiceMention {
    width: 100%;
    display: block;
  }

  .voiceMentionTxt {
    width: 100%;
  }

  .voiceMentionTxt p {
    padding: 2rem;
    font-size: 1.6rem;
    width: calc(100% + 3.2rem);
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    text-align: center;
    position: relative;
  }

  .voiceMentionTxt p:before {
    top: 0;
  }

  .voiceMentionTxt p:after {
    bottom: -2rem;
  }

  .voiceMentionImg {
    width: 11.2rem;
    height: 11.2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }

  .voiceDetail:before {
    border-left: 1.1rem solid transparent;
    border-right: 1.1rem solid transparent;
    border-bottom: 1.3rem solid #effdf7;
    right: calc(50% - 1.1rem);
    top: -1.1rem;
  }

  .voiceDetail {
    padding: 1.8rem 1.6rem 2.4rem;
  }

  .voiceDetail p {
    font-size: 1.4rem;
  }

  .voiceAct {
    text-align: center;
  }

  // .clinic {
  //   padding-top: 7rem;
  // }
  .clinicItem {
    display: block;
  }

  .clinicImg {
    height: 20.4rem;
    object-fit: cover;
  }

  .clinicImg,
  .clinicItemInfo {
    width: 100%;
  }

  .clinicItemInfo {
    padding: 2.4rem 2rem;
  }

  .clinicItemInfo h3 {
    font-size: 2.2rem;
  }

  .clinicItemInfoRef li {
    letter-spacing: 0;
    font-size: 1.35rem;
  }

  .thinkInfo {
    width: 100%;
    padding: 3.3rem 1.6rem;
  }

  .thinkInfo h3 {
    font-size: 1.8rem;
  }

  .thinkInfo > p {
    margin-top: 2.4rem;
    font-size: 1.3rem;
  }

  .thinkInfo > h3:not(:first-child) {
    margin-top: 3.2rem;
  }

  .thinkDetailList li:before {
    width: 1.246rem;
    height: 1.175rem;
  }

  .thinkDetailList li {
    padding-left: 2.5rem;
    line-height: 1.8;
    font-size: 1.3rem;
  }

  .thinkDetailList {
    width: 100%;
    padding: 2.5rem 1.2rem;
  }

  .thinkFlower {
    display: none;
  }

  .thinkManImg {
    width: 19rem;
    margin-right: 4.5rem;
    margin-left: auto;
  }

  .thinkManInfo p {
    font-size: 1rem;
  }

  .thinkManInfo img {
    width: 8.4rem;
    margin-top: 0.8rem;
  }

  .thinkManInfo {
    padding-right: 1.6rem;
  }

  .thinkMan {
    margin-top: 2rem;
  }

  .life h2 img {
    width: 11.6rem;
    margin-right: 1.4rem;
  }

  .life h2 {
    font-size: 2.9rem;
  }

  .life h2 span {
    font-size: 3rem;
  }

  .life p {
    margin-top: 2.4rem;
  }

  .life:after {
    top: -4rem;
    left: calc(50% - 20.7rem);
    bottom: -4rem;
    width: 100%;
  }

  .faqList dt {
    padding: 2.5rem 6rem 2rem 4.8rem;
    font-size: 1.5rem;
  }

  .faqList dt:before {
    top: 2.5rem;
  }

  .faqList dd span {
    padding: 2.6rem 1.6rem 2.6rem 4.8rem;
    font-size: 1.4rem;
    letter-spacing: 0.037rem;
    margin-top: 2.6rem;
    margin-bottom: 2.4rem;
  }

  .faqList dd span:before {
    top: 2.5rem;
    left: 2rem;
  }

  .faqList dt:after {
    right: 2rem;
    width: 1.6rem;
    height: 1.6rem;
    top: calc(50% - 0.8rem);
  }

  .contactImg {
    content: url(../images/new-images/202309/sp_footer.jpg);
  }

  .contactInfo h2 {
    font-size: 2.3rem;
    text-align: left;
  }

  .contactInfo p {
    font-size: 1.5rem;
  }

  .contactAct {
    margin-top: 3.5rem;
    display: block;
  }

  // .contactAct>.btn:not(:first-child) {
  //   margin-top: 1.6rem;
  // }

  // .clinic_btn a {
  //   margin-left: 33.5px;
  //   width: 26.5rem;
  // }

  // .preparation_set_img {
  //   padding-top: 15px;

  //   .reparation_img {
  //     width: 35.2rem;
  //     border: 2px solid #e4e4e4;
  //   }
  // }

  // .sp_divider {
  //   display: block;
  // }

  // .sp_tel_area {
  //   display: block;
  // }
}
