.privacy_policy,
.terms,
.faq,
.transactions_policy {
    h2 {
        margin:0;
        padding:0;
        border:0;
        outline:0;
        font-size:100%;
        font-weight: bold;
        vertical-align:baseline;
        background:transparent;
    }

    h3,
    h4 {
        font-weight: bold;
    }

    .other_layout {
        padding: 60px 40px;
        @media #{$sp} {
              padding: 80px 30px;
        }
        .underline {
          text-decoration: underline;
        }
      }
}

